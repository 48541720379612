(function () {
  'use strict';

  class ModulesCtrl {
    constructor() {
      let vm = this;
      vm.ctrlName = 'ModulesCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.modules.controller:ModulesCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.modules')
    .controller('ModulesCtrl', ModulesCtrl);
}());
